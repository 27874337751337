import React from "react";
import UsersCreate from "./UsersCreate";
import { UsersCreateContextProvider } from "../services/usersCreateContext";

const index = () => {
  return (
    <UsersCreateContextProvider>
      <UsersCreate />
    </UsersCreateContextProvider>
  );
};

export default index;
