import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const login = localStorage.getItem("login");

    if (login !== "login") {
      navigate("/login");
    }
  }, [location]);
  return <Outlet />;
};

export default Layout;
