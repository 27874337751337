import api from "common/api";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

const Context = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await api.common.getUsers(1, 100);
      setData(response?.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  return {
    state: { data, loading },
    actions: {},
  };
};

const UsersListContext = createContext<any>({ state: {}, actions: {} });

export const UsersListContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <UsersListContext.Provider value={value}>
      {children}
    </UsersListContext.Provider>
  );
};

export default function useUsersListContext() {
  return useContext<ReturnType<typeof Context>>(UsersListContext);
}
