import { Button, Divider, Flex, message, Space } from "antd";
import useUsersInfoContext from "../services/usersInfoContext";

const UsersInfo = () => {
  const {
    state: { data },
  } = useUsersInfoContext();
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://biizpay.com?c=${data?.code}`
      );
      message.success("Copied");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div style={{ padding: "24px" }}>
      <Button onClick={handleCopy} type="primary">
        Copy code
      </Button>
      <Flex vertical>
        <Flex justify="space-between">
          <p>
            <b>Name:</b>
          </p>
          <p>{data?.name}</p>
        </Flex>
        <Divider style={{ margin: "0" }} />
        <Flex justify="space-between">
          <p>
            <b>Note:</b>
          </p>
          <p>{data?.note}</p>
        </Flex>
        <Divider style={{ margin: "0" }} />
        <Flex justify="space-between">
          <p>
            <b>Type:</b>
          </p>
          <p>{data?.type}</p>
        </Flex>
        <Divider style={{ margin: "0" }} />
        <Flex justify="space-between">
          <p>
            <b>Code:</b>
          </p>
          <p>{data?.code}</p>
        </Flex>
        <Divider style={{ margin: "0" }} />
        <h3>Images:</h3>
        <Flex gap={20} wrap>
          {data?.photos?.map((photo: any) => (
            <div>
              <a
                href={"https://api.biizpay.com/uploads/" + photo?.filename}
                target="_blank"
              >
                <img
                  src={"https://api.biizpay.com/uploads/" + photo?.filename}
                  width={350}
                />
              </a>
              <p>
                <b>IP:</b> {photo?.ip}
              </p>
              <p>
                <b>Time:</b> {photo?.time}
              </p>
              <p>
                <b>Code:</b> {photo?.category}
              </p>
              <p>
                <b>Port:</b> {photo?.port}
              </p>
            </div>
          ))}
        </Flex>
      </Flex>
    </div>
  );
};

export default UsersInfo;
