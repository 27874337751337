import React from "react";
import UsersList from "./UsersList";
import { UsersListContextProvider } from "../services/usersListContext";

const index = () => {
  return (
    <UsersListContextProvider>
      <UsersList />
    </UsersListContextProvider>
  );
};

export default index;
