import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Dashboard, Layout } from "./components";
import { Login, UsersCreate, UsersInfo, UsersList } from "routes";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
        children: [
          {
            path: "users",
            children: [
              {
                path: "list",
                element: <UsersList />,
              },
              {
                path: "info/:id",
                element: <UsersInfo />,
              },
              {
                path: "create/:id?",
                element: <UsersCreate />,
              },
            ],
          },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={routes} />;
}

export default App;
