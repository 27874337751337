import { Form, FormProps, message } from "antd";
import api from "common/api";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

const Context = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish: FormProps["onFinish"] = async (values) => {
    try {
      setLoading(true);
      const response = await api.common.create(
        id ? "update" : "create",
        id,
        values
      );
      message.success(id ? "Updated successfully" : "Created successfully");
      navigate("/users/list");
      setLoading(false);
    } catch (err) {
      message.error("Failed");
      setLoading(false);
      console.log(err);
    }
  };

  const getInfo = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.common.getInfo(id);
      form.setFieldsValue(response?.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (id) {
      try {
        setDeleteLoading(true);
        const response = await api.common.delete(id);
        message.success("Deleted successfully");
        setDeleteLoading(false);
        navigate("/users/list");
      } catch (err) {
        setDeleteLoading(false);
        message.error("Failed delete");
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (id) getInfo(id);
  }, [id]);

  return {
    state: { loading, form, deleteLoading },
    actions: { onFinish, handleDelete },
  };
};

const UsersCreateContext = createContext<any>({ state: {}, actions: {} });

export const UsersCreateContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <UsersCreateContext.Provider value={value}>
      {children}
    </UsersCreateContext.Provider>
  );
};

export default function useUsersCreateContext() {
  return useContext<ReturnType<typeof Context>>(UsersCreateContext);
}
