import { Api } from "../api";

export class CommonApi extends Api {
  async getUsers(_start: number, _end: number) {
    return await this.execute<any>(
      "get",
      `get-user?_start=${_start}&_end=${_end}`,
      null,
      null,
      undefined
    );
  }

  async getInfo(id: string) {
    return await this.execute<any>(
      "get",
      `get-user-info/${id}`,
      null,
      null,
      undefined
    );
  }

  async delete(id: string) {
    return await this.execute<any>(
      "post",
      `delete_user/${id}`,
      null,
      null,
      undefined
    );
  }

  async login(payload: { username: string; password: string }) {
    return await this.execute<any>("post", `login`, payload, null, undefined);
  }

  async create(
    type: "update" | "create" = "create",
    id?: string,
    payload: any = {}
  ) {
    return await this.execute<any>(
      "post",
      `${type === "create" ? `add-user` : `update_user/${id}`}`,
      payload,
      null,
      undefined
    );
  }
}
