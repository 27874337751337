import React from "react";
import useUsersListContext from "../services/usersListContext";
import { Button, Space, Table, TableProps, Tag } from "antd";
import { EditFilled } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

interface DataType {
  key: string;
  name: string;
  code: string;
  type: string;
  note: string;
  [key: string]: any;
}

const UsersList = () => {
  const navigate = useNavigate();

  const {
    state: { data, loading },
  } = useUsersListContext();

  const handleEditClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation(); // This will stop the row click event from being triggered
    navigate(`/users/create/${id}`);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      key: "action",
      render: (record, row) => (
        <Button
          icon={<EditFilled />}
          shape="circle"
          type="primary"
          onClick={(e) => handleEditClick(e, row?._id)}
        />
      ),
      align: "end",
    },
  ];

  return (
    <Table<DataType>
      loading={loading}
      columns={columns}
      dataSource={data}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => navigate(`/users/info/${record?._id}`),
          style: { cursor: "pointer" },
        };
      }}
    />
  );
};

export default UsersList;
