import { Button, Flex, Form, Input, Select } from "antd";
import useUsersCreateContext from "../services/usersCreateContext";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

type FieldType = {
  name: string;
  note: string;
  type: string;
  code: string;
};

const UsersCreate = () => {
  const { id } = useParams();
  const {
    state: { loading, form, deleteLoading },
    actions: { onFinish, handleDelete },
  } = useUsersCreateContext();
  return (
    <Form
      form={form}
      name="basic"
      style={{ width: "100%", padding: "24px" }}
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item<FieldType>
        label="Name"
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType>
        label="Note"
        name="note"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item<FieldType>
        label="Type"
        name="type"
        rules={[{ required: true }]}
      >
        <Select
          options={[
            {
              value: "telegram",
              label: "Telegram",
            },
            {
              value: "instagram",
              label: "Instagram",
            },
          ]}
        />
      </Form.Item>

      <Form.Item<FieldType>
        label="Code"
        name="code"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Flex justify={id ? "space-between" : "flex-end"}>
        {id && (
          <Button
            type="primary"
            style={{ backgroundColor: "red" }}
            icon={<DeleteOutlined />}
            onClick={handleDelete}
            loading={deleteLoading}
          >
            Delete
          </Button>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
};

export default UsersCreate;
