import React, { useEffect } from "react";
import type { FormProps } from "antd";
import { Button, Checkbox, Flex, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import api from "common/api";

type FieldType = {
  username: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      localStorage.setItem("login", "login");
      const response = await api.common.login(values);
      navigate("/users/list", {replace: true});
    } catch (err) {
      message.error("Password or username is incorrect");
      console.log(err);
    }
  };

  useEffect(() => {
    localStorage.removeItem("login")
  }, [])

  return (
    <Flex
      justify="center"
      align="center"
      style={{ height: "100vh", width: "100%" }}
    >
      <Form style={{ maxWidth: 600 }} onFinish={onFinish} layout="vertical">
        <Form.Item<FieldType>
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default Login;
