import { OpenAIFilled, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Flex,
  Layout,
  Menu,
  MenuProps,
  Space,
  theme,
  Typography,
} from "antd";
import { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

const { Header, Content, Sider } = Layout;

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "users",
      icon: <UserOutlined />,
      label: <Link to={"/users/list"}>Users</Link>,
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/users/list");
    }
  }, [location]);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          background: "white",
          borderBottom: "1px solid #f5f5f5",
          paddingLeft: "25px",
          paddingRight: "25px",
        }}
      >
        <Flex justify="space-between" style={{ width: "100%" }} align="center">
          <Typography.Title level={3} style={{ margin: 0, padding: 0 }}>
            <OpenAIFilled /> Hidden camera
          </Typography.Title>
          <Space>
            <Button
              type="default"
              onClick={() => {
                localStorage.removeItem("login");
                navigate("/");
              }}
            >
              Log out
            </Button>
            <Link to={`/users/create`}>
              <Button type="primary">Create link</Button>
            </Link>
          </Space>
        </Flex>
      </Header>
      <Layout>
        <Sider width={200} style={{ background: colorBgContainer }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["users"]}
            style={{ height: "100%", borderRight: 0 }}
            items={items}
          />
        </Sider>
        <Layout style={{ padding: "24px 24px 24px" }}>
          <Content
            style={{
              // padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
